<template>
  <div class="charts-container">
    <div class="main-wrapper">
      <Header />
      <div class="main-container">
        <!-- 左边模块 -->
        <div class="left-container">
          <div class="left-top left-box">
            <h3 class="title-box">总体任务进度</h3>
            <div class="chart-box">
              <div id="prograss" class="prograss-box"></div>
            </div>
          </div>
          <div class="left-middle left-box">
            <h3 class="title-box">培训人次走势</h3>
            <div class="chart-box">
              <div id="trend" class="trend-box"></div>
            </div>
          </div>
          <div class="left-bottom left-box">
            <h3 class="title-box">培训任务量月度分布</h3>
            <div class="chart-box">
              <div id="distribute" class="distribute-box"></div>
            </div>
          </div>
        </div>

        <div class="center-container">
          <div class="center-top">
            <div class="row-box">
              <div class="item-box">
                <div class="item-time-right">
                  <div class="times-box">
                    <ICountUp
                      :delay="1000"
                      :endVal="totalStudyDuration"
                      :options="options"
                    />
                  </div>
                  <span>学时</span>
                </div>
                <div class="title-box">项目培训任务总学时</div>
              </div>
              <div class="item-box">
                <div class="item-time-right">
                  <div class="times-box">
                    <ICountUp
                      :delay="1000"
                      :endVal="totalFinishDuration"
                      :options="options"
                    />
                  </div>
                  <span>学时</span>
                </div>
                <div class="title-box">已完成总学时</div>
              </div>
            </div>
            <div class="row-box">
              <div class="item-box">
                <div class="item-time-right">
                  <div class="times-box">
                    <ICountUp
                      :delay="1000"
                      :endVal="totalTeams"
                      :options="optionsInt"
                    />
                    <!-- <span>{{totalTeams}}</span> -->
                  </div>
                  <span>个</span>
                </div>
                <div class="title-box">参与培训班组数</div>
              </div>
              <div class="item-box">
                <div class="item-time-right">
                  <div class="times-box">
                    <ICountUp
                      :delay="1000"
                      :endVal="totalTeamItems"
                      :options="optionsInt"
                    />
                    <!-- <span>{{totalTeamItems}}</span> -->
                  </div>
                  <span>个</span>
                </div>
                <div class="title-box">参与培训总人数</div>
              </div>
              <div class="item-box">
                <div class="item-time-right">
                  <div class="times-box">
                    <ICountUp
                      :delay="1000"
                      :endVal="totalTrainTimes"
                      :options="optionsInt"
                    />
                    <!-- <span>{{totalTrainTimes}}</span> -->
                  </div>
                  <span>次</span>
                </div>
                <div class="title-box">参与培训总人次</div>
              </div>
            </div>
          </div>
          <div class="chart-box center-chart-box">
            <h3 class="title-box">任务类型学时分布</h3>
            <div id="task-type-hour" class="task-type-hour"></div>
          </div>
        </div>
        <div class="right-container">

          <div class="right-top">
            <h3 class="title-box">班组培训完成率排行榜（Top10）</h3>
            <div class="efficient" id="efficient" />
            <!-- <div class="table-main">
              <div class="row-item">
                <div class="item-box">班组名称</div>
                <div class="item-box">完成率</div>
              </div>
              <div
                class="row-item row-hover"
                v-for="(item, index) in teamStatVos"
                :key="index"
              >
                <div class="item-box">{{ item.teamName }}</div>
                <div class="item-box">{{ item.efficient }}</div>
              </div>
            </div> -->
          </div>


          <div class="right-bottom">
            <h3 class="title-box">施工员培训效率排行榜（Top10）</h3>
            <div class="table-main">
              <div class="row-item">
                <div class="item-box">班组名称</div>
                <div class="item-box">施工员姓名</div>
                <div class="item-box">效率值</div>
              </div>
              <div
                class="row-item row-hover"
                v-for="(item, index) in teamItemStatVos"
                :key="index"
              >
                <div class="item-box item-box-overflow">
                  <Tooltip max-width="200" :content="item.teamName" :disabled="item.teamName.length >= 6 ? false : true" placement="right-start">
                    {{ item.teamName }}
                  </Tooltip>
                </div>
                <div class="item-box">{{ item.teamItemName }}</div>
                <div class="item-box">{{ item.efficient }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import options from './chartsOpts'
import ICountUp from 'vue-countup-v2'
import Header from './components/TheHeader'

export default {
  name: 'DGCharts',
  data() {
    return {
      pid: '',  // 项目ID
      taskData: null,
      totalStudyDuration: 0,  // 项目培训任务总学时
      totalFinishDuration: 0,  // 已完成总学时
      totalTeams: 0,  // 参与培训班组数
      totalTeamItems: 0,  // 参与培训总人数
      totalTrainTimes: 0,  // 参与培训总人次
      taskTypes: null,
      teamStatVos: null, // 班组培训完成率排行榜
      teamItemStatVos: null, // 施工员培训效率排行榜
      options: {
        useEasing: true,
        useGrouping: true,
        decimalPlaces: 2,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      optionsInt: {
        useEasing: true,
        useGrouping: true,
      }
    }
  },
  components: {
    Header,
    ICountUp
  },
  created() {
    this.pid = this.$route.query.pId
    this.fetchChartsData()
  },
  mounted() {
  },
  methods: {
    initCharts(domId, options) {
      var dom = document.getElementById(domId);
      var myChart = echarts.init(dom);
      options = this.chartDataInsert(this.taskData, options, domId)
      myChart.setOption(options)
    },
    chartDataInsert(cData, opts, type) {
      if (typeof opts === 'object') {
        switch (type) {
          case 'prograss':
            opts.series[0].data[0].value = cData.taskStatusVo.totalFinishTasks
            opts.series[0].data[1].value = cData.taskStatusVo.totalTasks - cData.taskStatusVo.totalFinishTasks
            break;
          case 'trend':
            var xtData = cData.monthStatTimes.map(item => item.monthStr)
            var trendData = cData.monthStatTimes.map(item => item.totalTrainTimes)
            opts.xAxis.data = xtData
            opts.series[0].data = trendData
            break;
          case 'distribute':
            var xdData = cData.monthStat.map(item => item.monthStr)
            var distributeData = cData.monthStat.map(item => item.totalTrainTimes)
            opts.xAxis[0].data = xdData
            opts.series[0].data = distributeData
            break;
          case 'efficient':{
            // teamStatVos
            let category = cData.teamStatVos.map(vos => vos.teamName)
            let barData = cData.teamStatVos.map(vos => vos.efficientVal * 100)
            let lineData = cData.teamStatVos.map(vos => 100)
            let maxFontNum = 6
            let newCategory = category.map(val => {
              if(val.length > maxFontNum){
                val = val.substring(0,maxFontNum) + '...'
              }
              return val
            })
            opts = options.efficientOpts({
              newCategory,
              category,
              barData,
              lineData
            })
            break;
          }
          case 'task-type-hour':
            var typeData = cData.taskTypes.map(item => {
              return {
                value: item.duration,
                name: item.taskTypeStr
              }
            })
            var typeName = cData.taskTypes.map(item => {
              return {
                name: item.taskTypeStr
              }
            })
            opts.legend.data = typeName
            opts.series[0].data = typeData
            break;
          default:
            break;
        }
      }
      return opts
    },
    async fetchChartsData() {
      let info = this.common.getStorage("user_info")
      if (info) {
        let res = await this.$fetch('/sapi/stat/projectStat', {
          method: "post",
          data: {
            icorpid: info.icorpid,
            projectId: this.pid
          }
        })
        if (res) {
          const retData = res.data
          const { totalStudyDuration, totalFinishDuration, totalTeams, totalTeamItems, totalTrainTimes, teamStatVos, teamItemStatVos } = retData
          this.taskData = retData
          this.totalStudyDuration = totalStudyDuration
          this.totalFinishDuration = totalFinishDuration
          this.totalTeams = totalTeams
          this.totalTeamItems = totalTeamItems
          this.totalTrainTimes = totalTrainTimes
          this.teamStatVos = teamStatVos
          this.teamItemStatVos = teamItemStatVos
          this.initCharts('prograss', options.optPrograss)
          this.initCharts('trend', options.optTrend)
          this.initCharts('distribute', options.optTaskDistribute)
          this.initCharts('task-type-hour', options.otpTaskType)
          this.initCharts('efficient', options.efficientOpts())
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
>>> .ivu-tooltip
  display block
>>> .ivu-tooltip-rel
  display block
  overflow: hidden
  text-overflow:ellipsis
  white-space: nowrap
.title-box
  color $green
  font-size 18px
  // &::before
  //   display inline-block
  //   content ''
  //   margin-right 8px
  //   width 6px
  //   height 6px
  //   border-top 6px solid transparent
  //   border-left 6px solid $green
  //   border-bottom 6px solid transparent
body
  background #fff url('../../assets/images/blue_bg.png') repeat center!important

.item-time-right
  display flex
  align-items center
  font-size 22px

.charts-container
  padding 0px 0 20px
  min-width 1440px
  color $green
  background #fff url('../../assets/images/blue_bg.png') repeat center
  background-size 100% 100%
  .main-wrapper
    margin 0 auto
    width 1440px
  .main-container
    display flex
    justify-content center
    .left-container
      display flex
      flex-direction column
      align-items center
      padding 10px 8px
      width 400px
      flex 0 0 400px
      background: rgba(49,50,88,0.31) url('../../assets/images/1.png') center top;
      // background rgba(49, 50, 88, 0.31) url('../../assets/images/1.png') no-repeat center
      .left-box
        width 100%
      // .title-box
      //   title-box()
      .prograss-box
        width 100%
        height 260px
      .trend-box
        padding 10px 0
        width 100%
        height 240px
      .distribute-box
        width 100%
        height 280px
      .left-bottom
        margin-top 20px
        height 320px
    .center-container
      position relative
      flex 1
      margin 44px 12px 0
      // padding 0 92px
      // .title-box
      //   color #f8ca7e
      //   font-size 18px
      .center-top
        padding 42px 20px
        background rgba(49,50,88,0.31) url('../../assets/images/3.png') center
      .row-box
        display flex
        justify-content space-between
        &:first-child
          justify-content: space-around;
          margin-bottom 50px
        .times-box
          margin-right 5px
          border 1px solid rgba(16, 44, 163, 1)
          box-shadow 0 0 20px rgba(16, 44, 163, 1) inset
          background rgba(16,44,163,0.08)
          text-align center
          // background: rgba(49,50,88,0.31) url('../../assets/images/3.png') center;
          span:first-child
            display inline-block
            min-width 160px
            padding 0 10px
            font-size 48px
      .chart-box
        margin-top 28px
        padding 10px
        // height 460px
        background rgba(49,50,88,0.31) url('../../assets/images/4.png') no-repeat center
        background-size 100% 100%
        &.center-chart-box
          height 506px
      .task-type-hour
        margin 0 auto
        width 100%
        height 450px
    .right-container
      width 25%
      .right-top
        padding 10px
        width 100%
        background url('../../assets/images/5.png') center
        background-size 100% 100%
      // .title-box
      //   color $
      //   font-size 18px
      .right-bottom
        padding 10px
        background url('../../assets/images/6.png') center
        background-size 100% 100%
      .efficient
        height 400px
      .table-main
        margin 10px 0 0
        height 410px
        overflow hidden
        // background rgba(0, 71, 168, 0.85)
        .row-item
          display flex
          justify-content space-between
          padding 10px 8px
          text-align center
          transition all .3s linear
          &:first-child
            padding 13px 10px
            background #042E74
            border-radius 4px
          &:nth-child(odd)
            background rgba(0,42,111,0.6)
            border-radius 4px
          &.row-hover:hover
            cursor pointer
            background rgba(76, 134, 228, 0.6)
            transition all .3s linear
          .item-box-overflow
            display: block !important
            overflow: hidden
            text-overflow:ellipsis
            white-space: nowrap
          .item-box
            display flex
            justify-content center
            align-items center 
            &:nth-child(1)
              width 140px
            &:nth-child(2)
              width 140px
            &:nth-child(3)
              width 140px
            &:nth-child(4)
              width 80px
            &:nth-child(5)
              width 80px
            &:nth-child(6)
              width 80px
            &:nth-child(7)
              width 120px
            &:last-child
              position relative
              .iconfont
                position absolute
                right 0
          
</style>


